import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    resetLocalOutputAudio({ commit, getters }) {
      commit("setLocalOutputAudio", getters.audioOutputs[0]);
    },
    resetLocalSourceAudio({ commit, getters }) {
      commit("setLocalSourceAudio", getters.audioInputs[0]);
    },
    resetLocalSourceVideo({ commit, getters }) {
      commit("setLocalSourceVideo", getters.videoInputs[0]);
    },
  },
  mutations: {
    setLocalDevices(state, value) {
      state.localDevices = value;
    },
    setLocalStream(state, value) {
      state.localStream = value;
    },
    setLocalSourceAudio(state, value) {
      state.localSourceAudio = value;
    },
    setLocalSourceVideo(state, value) {
      state.localSourceVideo = value;
    },
    setLocalOutputAudio(state, value) {
      state.localOutputAudio = value;
    },
    setStatus(state, value) {
      state.status = value;
    },
    setRemoteStream(state, value) {
      console.log(`change remote stream with ${value}`);
      state.remoteStream = value;
    },
    setCall(state, value) {
      state.call = value;
    },
    setLocalSwitchAudio(state, value) {
      state.localSwitchAudio = value;
    },
    setLocalSwitchVideo(state, value) {
      state.localSwitchVideo = value;
    },
    setRemoteSwitchAudio(state, value) {
      state.remoteSwitchAudio = value;
    },
    setRemoteSwitchVideo(state, value) {
      state.remoteSwitchVideo = value;
    },
    setAlert(state, { title, details, icon }) {
      state.alertTitle = title;
      state.alertDetails = details;
      state.alertIcon = icon;
    },
    setWsConnected(state, value) {
      state.isWsConnected = value;
    },
    setIsOpponentJoined(state, value) {
      state.isOpponentJoined = value;
    },
  },
  state: {
    call: null,
    status: null,
    isWsConnected: false,
    isOpponentJoined: false,

    localStream: null,
    localDevices: [],
    localSourceAudio: null,
    localSourceVideo: null,
    localOutputAudio: null,

    localSwitchAudio: true,
    localSwitchVideo: true,

    remoteStream: null,

    remoteSwitchAudio: null,
    remoteSwitchVideo: null,

    alertTitle: null,
    alertDetails: null,
    alertIcon: null,
  },
  getters: {
    localStream(state) {
      return state.localStream;
    },

    localDevices(state) {
      return state.localDevices;
    },

    audioInputs(state) {
      return state.localDevices.filter((device) => device.kind == "audioinput");
    },

    videoInputs(state) {
      return state.localDevices.filter((device) => device.kind == "videoinput");
    },

    audioOutputs(state) {
      return state.localDevices.filter(
        (device) => device.kind == "audiooutput"
      );
    },
  },
});
