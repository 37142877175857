<template>
  <!-- <div id="app" data-app> -->
  <v-app>
    <!-- -->
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
  <!-- </div> -->
</template>

<script>
export default {
  name: "App",
  mounted() {
    let locale = localStorage.getItem("locale");
    if (locale) {
      this.$i18n.locale = locale;
    }
  },
  watch: {
    "$i18n.locale": function (newVal) {
      localStorage.setItem("locale", newVal);
    },
  },
};
</script>

<style>
html {
  /* background: #021521; */
  max-height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
