<template>
  <v-container fill-height fluid id="disclaimer">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card width="600px" align="left">
          <v-card-title
            ><h2 class="font-weight-black">Link To Call Me</h2>
            <v-spacer></v-spacer
            ><v-icon large color="black"
              >mdi-transit-connection-variant</v-icon
            ></v-card-title
          >
          <v-card-subtitle class="text-subtitle-1"
            >www.link2call.me<br />
          </v-card-subtitle>

          <v-card-text class="text-body-1">
            <v-list>
              <v-list-item>
                <p>{{ $t("card.description") }}</p>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <!-- <v-list-item-title>
                    <p>
                      {{ $t("card.toStart") }}
                    </p></v-list-item-title
                  > -->
                  <v-btn block outlined @click="newCall" x-large class="font-weight-black"
                    >{{ $t("buttons.newCall") }}
                    <v-icon>mdi-open-in-new</v-icon></v-btn
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <!-- <p>
                    {{ $t("card.toConnect") }}
                  </p> -->
                  <v-form v-on:submit.prevent="join">
                    <v-text-field
                      v-model="newCallGuid"
                      :label="$t('labels.callID')"
                    ></v-text-field>

                    <v-btn
                      block
                      outlined
                      @click="join"
                      :disabled="!newCallGuid"
                    >
                      {{ $t("buttons.join") }}
                      <v-icon>mdi-open-in-app</v-icon>
                    </v-btn>
                  </v-form>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <!-- <p>{{ $t("card.selectLanguage") }}</p> -->
                  <v-select
                    text-right
                    v-model="$root.$i18n.locale"
                    :items="langs"
                    :item-text="'name'"
                    :item-value="'value'"
                    :hint="$t('card.selectLanguage')"
                    persistent-hint
                  >
                  </v-select>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <small
        >Copyright © 2021
        <a
          href="https://www.linkedin.com/in/polyakov-pavel/"
          style="text-decoration: none"
          >Pavel Polyakov</a
        >. All rights reserved.</small
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  i18n: {
    messages: {
      en: {
        card: {
          description: "Create your peer-to-peer calls via browser easily",
          toStart: "Press the button to create a new call",
          toConnect: "Enter call's ID to connect existed call",
          selectLanguage: "Language",
        },
        buttons: {
          newCall: "New Call",
          join: "Join",
        },
        labels: {
          callID: "Call ID",
        },
      },
      ru: {
        card: {
          description:
            "Это сервис для простой связи друг с другом через браузер",
          toStart: "Чтобы начать новый звонок — нажмите кнопку",
          toConnect:
            "Чтобы присоединиться к существующему звонку — введите идентификатор в поле ниже",
          selectLanguage: "Язык",
        },
        buttons: {
          newCall: "Новый звонок",
          join: "Присоединиться",
        },
        labels: {
          callID: "Идентификатор звонка",
        },
      },
    },
  },
  methods: {
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    join() {
      this.$router.push("/" + this.newCallGuid);
    },
    newCall() {
      let uuid = this.uuidv4();
      this.$router.push("/" + uuid);
    },
  },
  data() {
    return {
      newCallGuid: null,
      errorMessage: null,
      langs: [
        { value: "en", name: "English" },
        { value: "ru", name: "Русский" },
      ],
    };
  },
  async mounted() {
    if (this.$store.state.call) {
      if (this.$store.state.call.ws) {
        await this.$store.state.call.ws.close();
      }
    }
  },
};
</script>
<style scoped>
#disclaimer {
  z-index: 1000;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>