import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import router from "./router";
import Clipboard from "v-clipboard";

// Vue.use(Clipboard);

import VueI18n from 'vue-i18n'

Vue.use(Vuex);
Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(Clipboard);
Vue.use(VueI18n)

new Vue({
  i18n: new VueI18n({
    // locale: 'ru',
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  }),
  vuetify: new Vuetify({
    // theme: { dark: light },
  }),
  render: (h) => h(App),
  router,
  store: store,
}).$mount("#app");
