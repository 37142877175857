<template>
  <div class="intro">
    <Disclaimer />
  </div>
</template>
<script>
import Disclaimer from "@/components/Disclaimer";
export default {
  name: "Intro",
  components: { Disclaimer },
};
</script>

<style scoped>
div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  color: #ffffff;
  z-index: 0;
}
</style>
